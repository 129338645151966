<template>
  <b-tabs
    v-if="!isLoading"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>
      <general-settings :value="generalSettings" />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Stripe</span>
      </template>
      <stripe-settings :value="stripeSettings" />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <ReferralIcon :size="20" />
        <span class="font-weight-bold">Referral Commission</span>
      </template>
      <referral-settings :value="referralCommissionSettings" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import StripeSettings from '@/views/admin/settings/components/StripeSettings.vue'
import StripeSettingsModel from '@/views/admin/settings/models/stripe-settings.model'
import store from '@/store'
import GeneralSettings from '@/views/admin/settings/components/GeneralSettings.vue'
import GeneralSettingsModel from '@/views/admin/settings/models/general-settings.model'
import ReferralCommissionModel from '@/views/admin/settings/models/referral-commission.model'
import ReferralSettings from '@/views/admin/settings/components/ReferralSettings.vue'
import ReferralIcon from '@/components/icons/ReferralIcon.vue'

export default {
  name: 'SystemSettings',
  components: {
    ReferralIcon,
    ReferralSettings,
    GeneralSettings,
    StripeSettings,
    BTabs,
    BTab,
  },
  setup() {
    const stripeSettings = ref(StripeSettingsModel.empty())
    const generalSettings = ref(GeneralSettingsModel.empty())
    const referralCommissionSettings = ref(ReferralCommissionModel.empty())
    const isLoading = ref(true)

    store.dispatch('systemSettings/fetchSettings').then(response => {
      const stripe = response.data.find(settings => settings.key === 'stripe')
      if (stripe) {
        stripeSettings.value = StripeSettingsModel.create(stripe.payload)
      }
      const general = response.data.find(settings => settings.key === 'general')
      if (general) {
        generalSettings.value = GeneralSettingsModel.create(general.payload)
      }
      const referralCommission = response.data.find(settings => settings.key === 'referral_commission')
      if (referralCommission) {
        referralCommissionSettings.value = ReferralCommissionModel.create(referralCommission.payload)
      }
    }).finally(() => {
      isLoading.value = false
    })
    return {
      stripeSettings,
      generalSettings,
      referralCommissionSettings,
      isLoading,
    }
  },
}
</script>

<style scoped>

</style>
