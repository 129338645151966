export default class StripeSettingsModel {
  constructor({
    testMode = false,
    publicKey = '',
    secretKey = '',
    testPublicKey = '',
    testSecretKey = '',

  }) {
    this.testMode = testMode
    this.publicKey = publicKey
    this.secretKey = secretKey
    this.webhookSecretKey = secretKey
    this.testPublicKey = testPublicKey
    this.testSecretKey = testSecretKey
    this.testWebhookSecretKey = testSecretKey
  }

  static create(data) {
    return new StripeSettingsModel({
      testMode: data.test_mode,
      publicKey: data.keys.prod.public_key,
      secretKey: data.keys.prod.secret_key,
      webhookSecretKey: data.keys.prod.webhook_secret_key,
      testPublicKey: data.keys.test.public_key,
      testSecretKey: data.keys.test.secret_key,
      testWebhookSecretKey: data.keys.test.webhook_secret_key,
    })
  }

  static empty() {
    return new StripeSettingsModel({})
  }
}
