export default class ReferralCommissionModel {
  constructor({
    value = 0,
    type = 'percentage',
  }) {
    this.value = value
    this.type = type
  }

  static create(data) {
    return new ReferralCommissionModel({
      value: data.value,
      type: data.type,
    })
  }

  static empty() {
    return new ReferralCommissionModel({})
  }
}
