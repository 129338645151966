<template>
  <b-card>
    <validation-observer
      ref="validationObserver"
    >
      <b-form
        id="stripe-settings"
        class="mt-2"
        @submit.prevent="onSubmit"
      >
        <div class="tw-flex tw-flex-col tw-gap-5">
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-lg tw-font-bold tw-mb-3 border-bottom-1">
              Sign Up
            </div>
            <b-form-checkbox
              v-model="optionsLocal.signUpInviteOnly"
              name="test_mode"
            >Sign Up Invite Only
            </b-form-checkbox>
          </div>
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-lg tw-font-bold tw-mb-3 border-bottom-1">
              Forecast
            </div>
            <div class="tw-flex tw-flex-col">
              <div class="dx-field-label tw-w-full">
                Avg. number of orders for recent 30 days to show Forecast
              </div>
              <div class="dx-field-value">
                <DxNumberBox
                  v-model="optionsLocal.avgNumberOfOrdersForRecent30Days"
                  class="tw-flex-1 tw-w-full"
                  placeholder="Number Of Orders"
                  value-change-event="keyup"
                  :min="0"
                />
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-row">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BCard,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import DxNumberBox from 'devextreme-vue/number-box'

export default {
  name: 'GeneralSettings',
  components: {
    BButton,
    BForm,
    BFormCheckbox,
    BCard,
    ValidationObserver,
    DxNumberBox,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      optionsLocal: {
        signUpInviteOnly: undefined,
        avgNumberOfOrdersForRecent30Days: 0,
      },
    }
  },
  watch: {
    value(value) {
      this.optionsLocal = JSON.parse(JSON.stringify(value))
    },
  },
  created() {
    this.optionsLocal = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    onSubmit() {
      this.$refs.validationObserver.validate().then(success => {
        if (success) {
          this.saving = true
          this.$store.dispatch('systemSettings/saveGeneralSettings', {
            sign_up_invite_only: this.optionsLocal.signUpInviteOnly,
            avg_number_of_orders_for_recent_30_days: this.optionsLocal.avgNumberOfOrdersForRecent30Days,
          }).then(data => {
            this.$bvToast.toast(data, {
              title: 'Success',
              toaster: 'b-toaster-top-center',
              variant: 'success',
              solid: true,
              appendToast: false,
            })
          }).catch(() => {
            this.$refs.validationObserver.setErrors({
              'Test Webhook Secret Key': 'Server error, please try again later',
            })
          })
            .finally(() => {
              this.saving = false
            })
        }
      }).catch(() => {
        this.$refs.profileFrom.setErrors({
          timezone: 'Server error, please try again later',
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
