<template>
  <b-card>
    <validation-observer
      ref="validationObserver"
    >
      <b-form
        id="stripe-settings"
        class="mt-2"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Public Key"
              label-for="public_key"
            >

              <validation-provider
                #default="{ errors }"
                name="Public Key"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.publicKey"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Public Key"
                  name="public_key"
                  autocomplete="off"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Secret Key"
              label-for="secret_key"
            >
              <validation-provider
                #default="{ errors }"
                name="Secret Key"
                rules="required"
              >

                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="optionsLocal.secretKey"
                    :type="secretTypeProd"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Secret Key"
                    name="secret_key"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="toggleSecretIconProd"
                      @click="toggleSecretVisibilityProd"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Webhook Secret Key"
              label-for="webhook_secret_key"
            >
              <validation-provider
                #default="{ errors }"
                name="Webhook Secret Key"
                rules="required"
              >

                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="optionsLocal.webhookSecretKey"
                    :type="webhookSecretTypeProd"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Webhook Secret Key"
                    name="webhook_secret_key"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="toggleWebhookSecretIconProd"
                      @click="toggleWebhookSecretVisibilityProd"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Test Public Key"
              label-for="test_public_key"
            >

              <validation-provider
                #default="{ errors }"
                name="Test Public Key"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.testPublicKey"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Test Public Key"
                  name="test_public_key"
                  autocomplete="off"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Test Secret Key"
              label-for="test_secret_key"
            >
              <validation-provider
                #default="{ errors }"
                name="Test Secret Key"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="optionsLocal.testSecretKey"
                    :type="secretTypeTest"
                    placeholder="Test Secret Key"
                    name="test_secret_key"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="toggleSecretIconTest"
                      @click="toggleSecretVisibilityTest"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Test Webhook Secret Key"
              label-for="test_webhook_secret_key"
            >
              <validation-provider
                #default="{ errors }"
                name="Test Webhook Secret Key"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="optionsLocal.testWebhookSecretKey"
                    :type="webhookSecretTypeTest"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Test Webhook Secret Key"
                    name="test_webhook_secret_key"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="toggleWebhookSecretIconTest"
                      @click="toggleWebhookSecretVisibilityTest"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-checkbox
              v-model="optionsLocal.testMode"
              name="test_mode"
            >Test Mode
            </b-form-checkbox>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  name: 'StripeSettings',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Validation rules
      required,

      saving: false,
      secretTypeProd: 'password',
      webhookSecretTypeProd: 'password',
      secretTypeTest: 'password',
      webhookSecretTypeTest: 'password',
      optionsLocal: {
        publicKey: undefined,
        secretKey: undefined,
        webhookSecretKey: undefined,
        testPublicKey: undefined,
        testSecretKey: undefined,
        testWebhookSecretKey: undefined,
        testMode: false,
      },
    }
  },
  computed: {
    toggleSecretIconProd() {
      return this.secretTypeProd === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    toggleWebhookSecretIconProd() {
      return this.webhookSecretTypeProd === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    toggleSecretIconTest() {
      return this.secretTypeTest === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    toggleWebhookSecretIconTest() {
      return this.webhookSecretTypeTest === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    value(value) {
      this.optionsLocal = JSON.parse(JSON.stringify(value))
    },
  },
  methods: {
    toggleSecretVisibilityProd() {
      this.secretTypeProd = this.secretTypeProd === 'password' ? 'text' : 'password'
    },
    toggleWebhookSecretVisibilityProd() {
      this.webhookSecretTypeProd = this.webhookSecretTypeProd === 'password' ? 'text' : 'password'
    },
    toggleSecretVisibilityTest() {
      this.secretTypeTest = this.secretTypeTest === 'password' ? 'text' : 'password'
    },
    toggleWebhookSecretVisibilityTest() {
      this.webhookSecretTypeTest = this.webhookSecretTypeTest === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.$refs.validationObserver.validate().then(success => {
        if (success) {
          this.saving = true
          this.$store.dispatch('systemSettings/saveStripeSettings', {
            test_mode: !!this.optionsLocal.testMode,
            keys: {
              prod: {
                public_key: this.optionsLocal.publicKey,
                secret_key: this.optionsLocal.secretKey,
                webhook_secret_key: this.optionsLocal.webhookSecretKey,
              },
              test: {
                public_key: this.optionsLocal.testPublicKey,
                secret_key: this.optionsLocal.testSecretKey,
                webhook_secret_key: this.optionsLocal.testWebhookSecretKey,
              },
            },
          }).then(data => {
            this.$bvToast.toast(data, {
              title: 'Success',
              toaster: 'b-toaster-top-center',
              variant: 'success',
              solid: true,
              appendToast: false,
            })
          }).catch(() => {
            this.$refs.validationObserver.setErrors({
              'Test Webhook Secret Key': 'Server error, please try again later',
            })
          })
            .finally(() => {
              this.saving = false
            })
        }
      }).catch(() => {
        this.$refs.profileFrom.setErrors({
          timezone: 'Server error, please try again later',
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
