export default class GeneralSettingsModel {
  constructor({ signUpInviteOnly = false, avgNumberOfOrdersForRecent30Days = 0 }) {
    this.signUpInviteOnly = signUpInviteOnly
    this.avgNumberOfOrdersForRecent30Days = avgNumberOfOrdersForRecent30Days
  }

  static create(data) {
    return new GeneralSettingsModel({
      signUpInviteOnly: data.sign_up.is_invite_only || false,
      avgNumberOfOrdersForRecent30Days: data.forecast.avg_number_of_orders_for_recent_30_days || 0,
    })
  }

  static empty() {
    return new GeneralSettingsModel({})
  }
}
