<template>
  <b-card>
    <validation-observer
      ref="validationObserver"
    >
      <b-form
        id="stripe-settings"
        class="mt-2"
        @submit.prevent="onSubmit"
      >
        <div class="tw-flex tw-flex-col">
          <b-form-group
            label="Commission Amount"
            label-for="commission_amount"
          >
            <validation-provider
              #default="{ errors }"
              name="Commission Amount"
              rules="required"
            >
              <b-form-input
                v-model="optionsLocal.value"
                :state="errors.length > 0 ? false:null"
                placeholder="Commission Amount"
                name="commission_amount"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Commission Type"
            label-for="commission_type"
          >
            <v-select
              v-model="optionsLocal.type"
              :options="typeOptions"
              :clearable="false"
              label="text"
              value="value"
              placeholder="Select Commission Type"
              name="commission_type"
              autocomplete="off"
            />
          </b-form-group>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          class="mt-2 mr-1"
        >
          Save changes
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BForm, BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ReferralSettings',
  components: {
    BFormGroup,
    BButton,
    BForm,
    BFormInput,
    BCard,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Validation rules
      required,
      saving: false,
      typeOptions: [
        { value: 'percentage', text: 'Percent' },
        { value: 'fixed', text: 'Fixed Amount' },
      ],
      optionsLocal: {
        value: 0,
        type: { value: 'percentage', text: 'Percent' },
      },
    }
  },
  watch: {
    value(value) {
      this.optionsLocal = JSON.parse(JSON.stringify(value))
      this.optionsLocal.type = this.typeOptions.find(option => option.value === value.type)
    },
  },
  mounted() {
    this.optionsLocal = JSON.parse(JSON.stringify(this.value))
    this.optionsLocal.type = this.typeOptions.find(option => option.value === this.value.type)
  },
  methods: {
    onSubmit() {
      this.$refs.validationObserver.validate().then(success => {
        if (success) {
          this.saving = true
          this.$store.dispatch('systemSettings/saveSettingsByKey', {
            key: 'referral_commission',
            payload: {
              value: this.optionsLocal.value,
              type: this.optionsLocal.type.value,
            },
          }).then(data => {
            this.$bvToast.toast(data, {
              title: 'Success',
              toaster: 'b-toaster-top-center',
              variant: 'success',
              solid: true,
              appendToast: false,
            })
          }).catch(() => {
            this.$refs.validationObserver.setErrors({
              'Commission Type': 'Server error, please try again later',
            })
          })
            .finally(() => {
              this.saving = false
            })
        }
      }).catch(() => {
        this.$refs.validationObserver.setErrors({
          'Commission Type': 'Server error, please try again later',
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
